import DatePicker from 'vue2-datepicker';
import { ModelSelect } from 'vue-search-select';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'ims',
  components: {
    ModelSelect,
    DatePicker,
    commonHelper
  },
  watch: {
    searchParams() {
      if (this.searchParams) {
        this.getImsData(this.searchParams);
      } else {
        this.imsData = [];
      }
    },
    totalRows() {
      this.$emit('totalRowsCount', this.totalRows);
    },
    currentPage: function() {
      this.searchParams._page = this.currentPage - 1;
      this.getImsData(this.searchParams);
    }
  },
  props: ['searchParams', 'inv_num'],
  data() {
    return {
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      payload: null,
      unsubscribe: null,
      loader: false,
      tab: null,
      imsData: [],
      imsFields: [
        {
          key: 'ims_status',
          label: 'IMS',
          stickyColumn: true,
          class: 'text-center'
        },
        {
          key: 'approve',
          stickyColumn: true,
          variant: 'primary',
          class: 'col-checkbox-widthFix text-center'
        },
        {
          key: 'reject',
          stickyColumn: true,
          variant: 'danger',
          class: 'col-checkbox-widthFix text-center'
        },
        {
          key: 'pending',
          stickyColumn: true,
          variant: 'warning',
          class: 'col-checkbox-widthFix text-center'
        },
        // {
        //     key: 'nat',
        //     label: 'NAT',
        //     stickyColumn: true
        // },
        {
          key: 'action',
          stickyColumn: true,
          class: 'text-center'
        },
        {
          key: 'gstn_no_supp',
          label: 'Supplier GSTN',
          class: 'col-fix'
        },
        {
          key: 'invoice_num',
          class: 'col-fix'
        },
        {
          key: 'invoice_date',
          label: 'Invoice Date',
          class: 'col-fix'
        },
        {
          key: 'invoice_value',
          class: 'col-fix'
        },
        {
          key: 'taxable_value',
          class: 'col-fix'
        },
        {
          key: 'igst_amount',
          label: 'IGST Amount',
          class: 'col-fix'
        },
        {
          key: 'cgst_amount',
          label: 'CGST Amount',
          class: 'col-fix'
        },
        {
          key: 'sgst_amount',
          label: 'SGST Amount',
          class: 'col-fix'
        },
        {
          key: 'cess_amount',
          label: 'CESS',
          class: 'col-fix'
        },
        {
          key: 'pos',
          label: 'POS',
          class: 'col-fix'
        },
        {
          key: 'invoice_type',
          class: 'col-fix'
        },
        {
          key: 'source_type',
          class: 'col-fix'
        },
        {
          key: 'submited_status',
          class: 'col-fix'
        },
        {
          key: 'form_type',
          class: 'col-fix'
        },
        {
          key: 'supplier_ret_period',
          class: 'col-fix'
        },
        {
          key: 'supplier_filling_status',
          class: 'col-fix'
        },
        {
          key: 'original_invoice_num',
          class: 'col-fix'
        },
        {
          key: 'original_invoice_date',
          class: 'col-fix'
        },
        {
          key: 'note_date',
          class: 'col-fix'
        },
        {
          key: 'note_number',
          class: 'col-fix'
        },
        {
          key: 'irn_applicable',
          label: 'IRN Applicable',
          class: 'col-fix'
        },

        // {
        //     key: 'gstn_no',
        //     label: 'GSTN Supplier',
        //     class: 'col-fix'
        // },
        {
          key: 'irn',
          label: 'IRN',
          class: 'col-fix'
        },
        {
          key: 'ispendactblocked',
          label: 'Is Pendact Blocked',
          class: 'col-fix'
        }
      ],
      dtlId: null,
      invoiceNum: null,
      selectAllApproveCheckBox: false,
      selectAllRejectCheckBox: false,
      selectAllPendingCheckBox: false
    };
  },
  mounted() {
    this.invoiceNum = this.inv_num;
    // this.getImsData();
  },
  methods: {
    getImsData(params) {
      let payload = this.searchParams;
      if (params) {
        payload = Object.assign(payload, params);
      }
      this.loader = true;
      this.$store
        .dispatch('itcReconciliation/getImsData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.imsData = response.data.data.page;
            this.selectAllApproveCheckBox = false;
            this.selectAllRejectCheckBox = false;
            this.selectAllPendingCheckBox = false;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedCheckBox(index, flag) {
      if (flag === 'A') {
        this.imsData[index].reject = false;
        this.imsData[index].pending = false;
        if (this.imsData[index].approve) {
          this.imsData[index].approve = true;
        } else {
          this.imsData[index].approve = false;
        }
      } else if (flag === 'R') {
        this.imsData[index].approve = false;
        this.imsData[index].pending = false;
        if (this.imsData[index].reject) {
          this.imsData[index].reject = true;
        } else {
          this.imsData[index].reject = false;
        }
      } else if (flag === 'P') {
        this.imsData[index].reject = false;
        this.imsData[index].approve = false;
        if (this.imsData[index].pending) {
          this.imsData[index].pending = true;
        } else {
          this.imsData[index].pending = false;
        }
      }
      this.imsData = JSON.parse(JSON.stringify(this.imsData));
    },
    selectAllApproveRejectPendingCheckBox(type) {
      this.selectAllApproveCheckBox =
        type === 'A' ? this.selectAllApproveCheckBox : false;
      this.selectAllRejectCheckBox =
        type === 'R' ? this.selectAllRejectCheckBox : false;
      this.selectAllPendingCheckBox =
        type === 'P' ? this.selectAllPendingCheckBox : false;
      this.imsData = this.imsData.map((elem, index) => {
        elem.approve = false;
        elem.reject = false;
        elem.pending = false;
        if (type === 'A' && elem.action !== 'A') {
          elem.approve = this.selectAllApproveCheckBox;
          if (this.selectAllApproveCheckBox) this.selectedCheckBox(index, type);
        } else if (type === 'R' && elem.action !== 'R') {
          elem.reject = this.selectAllRejectCheckBox;
          if (this.selectAllRejectCheckBox) this.selectedCheckBox(index, type);
        } else if (type === 'P' && elem.action !== 'P') {
          elem.pending = this.selectAllPendingCheckBox;
          if (this.selectAllPendingCheckBox) this.selectedCheckBox(index, type);
        }
        return elem;
      });
    },
    updateIms() {
      const filterData = this.imsData.filter(
        ele => ele.approve || ele.reject || ele.pending || ele.nat
      );
      if (filterData.length) {
        const itemDetails = filterData.map(elem => {
          return {
            dtl_id: elem.dtl_id,
            action: elem.approve
              ? 'A'
              : elem.reject
              ? 'R'
              : elem.pending
              ? 'P'
              : 'NAT'
          };
        });
        const payload = {
          getparams: itemDetails ? itemDetails : null
        };
        this.loader = true;
        this.$store
          .dispatch('itcReconciliation/updateImsActionDtls', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.itcDataUpdationData = resp.data.data.page;
              this.totalRows = resp.data.data.total_elements;
              this.getImsData();
              this.showAlert = true;
              this.isSuccess = true;
              this.respMessage = resp.data.message;
            } else {
              this.showAlert = true;
              this.isSuccess = false;
              this.respMessage = resp.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    }
  },
  beforeDestroy() {
    // this.unsubscribe();
  }
};
